<template>
  <div>
    Logout
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  created() {
    setTimeout(() => {
      this.logout()
        .then(() => {
          this.$router.push({ name: 'Login' });
        });
    }, 1000);
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
};
</script>

<style lang="scss" scoped>
</style>
